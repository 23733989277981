<template>
  <div class="flat-modal">
    <div class="flat-modal-top">
      <img
        v-if="flatData.images.length > 0"
        :src="flatData.images[0].file"
        class="flat-modal-top__img"
        :alt="`план ${flatData.title}`"
      />
      <RFIcon
        v-else
        class="flat-modal-top__img flat-modal-top__img--gray"
        icon-name="title"
      />
    </div>
    <section class="flat-modal-middle">
      <h2 class="flat-modal-middle__title">
        {{ flatData.name }}
      </h2>
      <span class="flat-modal-middle__text--green"
        >{{ priceFormat(flatData.cost) }} ₸</span
      >
      <div class="flat-modal-middle__grid">
        <div class="flat-modal-middle__item">
          <span class="flat-modal-middle__text--strong"
            >{{ flatData.full_area }} м<sup>2</sup></span
          >
          <span class="flat-modal-middle__text">Площадь</span>
        </div>
        <div class="flat-modal-middle__item">
          <span class="flat-modal-middle__text--strong"
            >{{
              `${flatData.completeDate.year} / ${formatQuarter(
                flatData.completeDate.quarter,
              )} квартал`
            }}
          </span>
          <span class="flat-modal-middle__text">Дата сдачи</span>
        </div>
        <div class="flat-modal-middle__item">
          <span class="flat-modal-middle__text--strong">{{
            flatData.floor
          }}</span>
          <span class="flat-modal-middle__text">Этаж</span>
        </div>
        <div class="flat-modal-middle__item">
          <span class="flat-modal-middle__text--strong"
            >{{ flatData.blockName }}
          </span>
          <span class="flat-modal-middle__text">Блок</span>
        </div>
      </div>
    </section>
    <div class="flat-modal-bottom">
      <h3 class="flat-modal-bottom__title">
        Оставить заявку
      </h3>
      <div class="flat-modal-bottom__tabs">
        <span class="flat-modal-middle__text">Выберите оплату</span>
        <Tabs :tabs="tabs" @select="getCurrentTab" />
      </div>
      <form @submit.prevent="submitData">
        <div class="flat-modal-bottom__grid">
          <div class="flat-modal-bottom__input">
            <span>Ваше имя</span>
            <input
              type="text"
              placeholder="Введите ваше имя"
              v-model="name"
              :class="{ success: name.length > 1, error: isError.name }"
            />
            <span v-if="isError.name" class="label-error"
              >Обязательное поле для заполнения</span
            >
          </div>
          <div class="flat-modal-bottom__input">
            <span>Телефон</span>
            <input
              placeholder="+7 (###) ###-##-##"
              v-mask="phoneMask"
              v-model="phone"
              :class="{ success: phone.length >= 18, error: isError.phone }"
            />
            <span v-if="isError.phone" class="label-error">{{
              1 > phone.length
                ? 'Обязательное поле для заполнения'
                : 'Введите корректный номер телефона'
            }}</span>
          </div>
          <RFButton
            :disabled="isError.phone || isError.name"
            class="flat-modal-bottom__btn"
            color="primary"
            @submit="submitData"
          >
            Оставить заявку
          </RFButton>
          <span v-if="isPending" class="flat-modal-middle__text--green"
            >Ваша заявка отправлена</span
          >
          <span v-if="isPendingError" class="flat-modal-middle__text--red"
            >Ошибка отправки</span
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import RFButton from '@/components/atoms/Buttons/index.vue'
import Tabs from '@/components/molecules/Tabs/index.vue'
import RFIcon from '@/components/atoms/Icons/index.vue'
import VueMask from 'v-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueMask, VueAxios, axios)

export default {
  name: 'FlatModalCard',
  components: { RFButton, Tabs, RFIcon },
  props: {
    flatData: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isShownModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMask: '+7 (###) ###-##-##',
      name: '',
      phone: '',
      newTel: '',
      isError: {
        phone: false,
        name: false,
      },
      hover: false,
      isShowModal: false,
      currentTab: 0,
      isPending: false,
      tabs: [
        {
          label: 'Наличными',
        },
        {
          label: 'В рассрочку',
        },
      ],
    }
  },
  computed: {
    // Чекаем урл прод/дев
    checkUrl() {
      return window.location.href.includes('capital.orda-invest.kz')
    },
    // Разделяем сумму на тысячи/млн и т.д.
    priceFormat() {
      return price => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
    setCurrentTab() {
      return this.currentTab === 0 ? 'Наличными' : 'В рассрочку'
    },
  },
  watch: {
    name: {
      handler() {
        this.isPending = false
        return (this.isError.name = false)
      },
    },
    phone: {
      handler() {
        this.isPending = false
        return (this.isError.phone = false)
      },
    },
  },
  methods: {
    formatQuarter(val) {
      switch (val) {
        case 2:
          return 'II'
        case 3:
          return 'III'
        case 4:
          return 'IV'
        default:
          return 'I'
      }
    },
    formatTel(val) {
      return val.replace(/[()+-\s]/g, '')
    },
    getCurrentTab(val) {
      return (this.currentTab = val)
    },
    async submitData() {
      this.checkError()
      if (!this.isError.name && !this.isError.phone) {
        this.formatTel(this.phone)
        await axios
          .post(
            this.checkUrl
              ? 'https://new.orda-invest.kz/api/v1/requests'
              : 'https://orda-invest-api.rocketfirm.tech/api/v1/requests',
            {
              phone: this.formatTel(this.phone),
              name: this.name,
              placement_id: this.flatData.id,
              type_id: this.setCurrentTab === 0 ? 1 : 2,
            },
          )
          .catch(e => {
            this.isPendingError = true

            setTimeout(() => {
              this.isPendingError = false
            }, 3000)
            return e
          })
        this.phone = ''
        this.name = ''
        setTimeout(() => {
          this.isPending = true
        }, 500)
      }
    },
    checkError() {
      if (this.phone.length < 18 && this.name.length < 1) {
        return (this.isError = { phone: true, name: true })
      } else if (this.name.length < 1) {
        return (this.isError.name = true)
      } else if (this.phone.length < 18) {
        return (this.isError.phone = true)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.flat-modal {
  width: 100%;
  height: fit-content;

  &-top {
    padding-bottom: 68px;
    @include mobile {
      padding-bottom: 18px;
    }
    &__img {
      width: 342px;
      height: 342px;
      margin: 0 auto;
      object-fit: fill;
      image-rendering: optimizeQuality;

      &--gray {
        object-fit: contain;
        background-color: $c-bg-gray;
      }

      @include mobile {
        width: 230px;
        height: 230px;
      }
    }
  }

  &-middle {
    padding: 40px;

    @include mobile {
      padding: 10px;
    }

    &__grid {
      display: grid;
      max-width: 400px;
      grid-template-columns: 0.7fr 1.3fr;
    }
    &__item {
      padding-top: 20px;
      @include flex-column();
    }
    &__title {
      padding-bottom: 30px;
      @include font-styles(38px, 46px, 700, $c-dark, 0, $font-second);

      @include mobile {
        padding-bottom: 16px;
        @include font-styles(22px, 26px, 700, $c-dark, 0, $font-second);
      }
    }

    &__text {
      @include font-styles(15px, 21px, 400, $c-gray);

      @include mobile {
        @include font-styles(12px, 16px, 400, $c-gray);
      }
    }

    &__text--strong {
      @include font-styles(16px, 22px, 600, $c-dark);

      sup {
        @include font-styles(12px, 16px, 600, $c-dark);
      }

      @include mobile {
        @include font-styles(14px, 18px, 700, $c-dark);
      }
    }
    &__text--green {
      @include font-styles(22px, 30px, 600, $c-main-green);

      @include mobile {
        text-align: center;
        @include font-styles(20px, 26px, 600, $c-main-green);
      }
    }
    &__text--red {
      @include font-styles(22px, 30px, 600, red);

      @include mobile {
        text-align: center;
        @include font-styles(20px, 26px, 600, red);
      }
    }
  }

  &-bottom {
    padding: 30px 40px;
    gap: 20px;
    background: $c-bg-light-gray;
    @include flex-column();

    @include mobile {
      gap: 14px;
      padding: 20px 10px;
    }

    form {
      gap: 20px;
      @include flex-column();
    }

    &__title {
      @include font-styles(26px, 36px, 600, $c-dark, 0, $font-second);

      @include mobile {
        @include font-styles(18px, 24px, 500, $c-dark, 0, $font-second);
      }
    }
    &__tabs {
      span {
        display: inline-block;
        padding-bottom: 15px;

        @include mobile {
          padding-bottom: 4px;
        }
      }
      &::v-deep .tab {
        gap: 15px;
        .primary {
          background: inherit;
          color: $c-dark;
          padding: 8px;
          border-radius: 0;

          &.tab--active,
          &:hover {
            border-bottom: 2px solid $c-main-green;
            background: inherit;
            color: $c-main-green;
          }
        }
      }
    }

    &__grid {
      display: grid;
      gap: 20px;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);

      @include mobile {
        gap: 10px;
        grid-template-columns: 1fr;
        margin-bottom: 80px;
      }
    }

    &__input {
      width: 100%;
      @include flex-column();
      span {
        display: inline-block;
        padding-bottom: 4px;
        @include font-styles(12px, 16px, 400, $c-gray);
      }
      input {
        padding: 12px;
        border-radius: 6px;
        border: 1px solid $c-border-gray;
        @include font-styles(16px, 24px, 400, $c-dark);

        &.error {
          border: 1px solid red;
        }

        &.success {
          border: 1px solid $c-main-green;
        }
      }

      .label-error {
        display: inline-block;
        padding-top: 2px;
        @include font-styles(12px, 16px, 400, red);
      }
    }

    &__btn {
      &:disabled {
        background: inherit !important;
      }
    }
  }
}
</style>
