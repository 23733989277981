<template>
  <div class="pagination">
    <span
      @click="changePage('left')"
      class="pagination__left"
      v-if="totalPages > 1 && currentPage !== 1"
    >
      <a class="pagination__number" href="#suggestions" v-smooth-scroll
        >Назад</a
      >
    </span>

    <span
      v-for="page in totalPages"
      :key="page"
      @click="changePage(page)"
      class="pagination__number"
      :class="{ 'pagination__number--active': currentPage === page }"
    >
      <a class="pagination__number" href="#suggestions" v-smooth-scroll>{{
        page
      }}</a>
    </span>

    <span
      @click="changePage('right')"
      class="pagination__right"
      v-if="totalPages > 1 && currentPage !== totalPages"
    >
      <a class="pagination__number" href="#suggestions" v-smooth-scroll
        >Далее</a
      >
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSmoothScroll from 'v-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 500,
})

export default {
  name: 'PaginationAtom',

  props: {
    totalPages: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  methods: {
    changePage(page) {
      switch (page) {
        case 'left':
          this.$emit(
            'select',
            this.currentPage !== 1 ? this.currentPage - 1 : 1,
          )
          this.currentPage = this.currentPage !== 1 ? this.currentPage - 1 : 1
          break
        case 'right':
          this.$emit(
            'select',
            this.currentPage !== this.totalPages
              ? this.currentPage + 1
              : this.totalPages,
          )
          this.currentPage =
            this.currentPage !== this.totalPages
              ? this.currentPage + 1
              : this.totalPages
          break
        default: {
          this.$emit('select', page)
          this.currentPage = page
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  @include flex-row();
  width: fit-content;
  justify-content: space-between;

  span {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
  }

  &__link {
    text-decoration: none;
  }

  &__number {
    @include font-styles(15px, 18px, 400, $c-dark);
    &--active {
      border-bottom: 1px solid $c-dark;
      a {
        font-weight: 800;
      }
    }
  }

  &__numbers {
    overflow: hidden;
  }
}
</style>
