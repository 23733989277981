<template>
  <div class="flat-block">
    <FlatCard
      v-for="(flat, i) in flatsData"
      :key="i"
      :flat-data="flat"
      @click.native="$emit('select', flat)"
    />
  </div>
</template>

<script>
import FlatCard from '@/components/atoms/Cards/FlatCard/index.vue'

export default {
  name: 'ClosePlace',
  components: { FlatCard },
  props: {
    flatsData: {
      type: Array,
      required: true,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.flat-block {
  flex-wrap: wrap;
  gap: 34px;
  @include flex-row();

  @include mobile {
    gap: 10px;
  }
}
</style>
