<template>
  <div class="modal">
    <div class="modal__body" v-click-outside="onClickOutside">
      <div class="modal__close" @click="onClickOutside">
        <RFIcon icon-name="x" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import RFIcon from '@/components/atoms/Icons/index.vue'
import vClickOutside from 'v-click-outside'

export default {
  components: { RFIcon },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1050;

  &__body {
    overflow-y: scroll;
    position: relative;
    padding-top: 68px;
    max-width: 640px;
    margin-left: calc(100% - 640px);
    width: 100%;
    height: 100vh;
    background: $white;
    animation: appearance 1s;

    @keyframes appearance {
      from {
        right: -100%;
      }
      to {
        right: 0;
      }
    }

    @include mobile {
      padding-top: 28px;
      margin-left: 0;
      max-width: none;
      width: 100vw;
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 36px;
    height: 36px;
    background: $gray-700;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 200;

    @include mobile {
      border-radius: 0;
      right: 15px;
      top: 18px;
      width: 24px;
      height: 24px;
    }
  }
  &__map {
    width: 100%;
    height: 100%;
  }
}
</style>
