<template>
  <div class="ninth" id="flats" v-if="flatsOneData[currentSlide]">
    <div class="ninth-wrapper">
      <div class="container">
        <FlatModal
          v-if="isShowFlatModal"
          :class="{ 'close-animation': isHideModal }"
          @close="closeModal"
        >
          <FlatCard
            :flat-data="isTop ? flatsOneData[currentSlide] : currentFlat"
          />
        </FlatModal>
        <h2 class="ninth__title wow" data-wow-offset="200">
          Квартира мечты ждет именно вас
        </h2>
        <div class="ninth-inner wow" data-wow-offset="250">
          <div class="ninth-left">
            <section class="ninth-left-bottom">
              <div
                class="ninth-left-bottom__tabs"
                :class="{ 'ninth-left-bottom__tabs--disabled': showAnim }"
              >
                <Tabs :tabs="tabs" @select="changeCurrentTab" />
              </div>
              <div class="ninth-left-bottom-img onlyTabletMin">
                <div
                  class="ninth-animation"
                  :class="{
                    'ninth-animation--active': showAnim,
                  }"
                >
                  <img
                    v-if="flatsOneData[currentSlide].images.length > 0"
                    :src="flatsOneData[currentSlide].images[0].file"
                    class="ninth-left-bottom-img__img"
                    :alt="`план ${flatsOneData[currentSlide].name}`"
                  />
                  <RFIcon
                    v-else
                    class="ninth-left-bottom-img__img--gray"
                    icon-name="title"
                  />
                </div>
              </div>
              <div
                class="ninth-left-bottom__card"
                :class="{ 'ninth-left-bottom__card--active': showAnim }"
              >
                <h3 class="ninth-left-bottom__title">
                  {{ flatsOneData[currentSlide].name }}
                </h3>
                <div class="ninth-left-bottom__flex">
                  <div class="ninth-left-bottom__area">
                    <span class="ninth-left-bottom__text--strong"
                      >{{ flatsOneData[currentSlide].full_area }} м<sup
                        >2</sup
                      ></span
                    >
                    <span class="ninth-left-bottom__text">Площадь</span>
                  </div>
                  <div class="ninth-left-bottom__date">
                    <span class="ninth-left-bottom__text--strong">{{
                      `${
                        flatsOneData[currentSlide].completeDate.year
                      } / ${formatQuarter(
                        flatsOneData[currentSlide].completeDate.quarter,
                      )} квартал`
                    }}</span>
                    <span class="ninth-left-bottom__text">Дата сдачи</span>
                  </div>
                </div>
                <span class="ninth-left-bottom__text--strong"
                  >от {{ priceFormat(flatsOneData[currentSlide].cost) }} ₸</span
                >
                <div class="ninth-left-bottom__btns">
                  <RFButton
                    class="ninth-left-bottom__btns--primary"
                    color="primary"
                    @click="openModal"
                  >
                    Посмотреть {{ textFormat(flatsOneMeta.total) }}
                  </RFButton>
                  <RFButton
                    class="ninth-left-bottom__btns--secondary"
                    secondary
                    @click="isShowFlatModal = true"
                  >
                    Подробнее
                  </RFButton>
                </div>
              </div>
            </section>
          </div>
          <div class="ninth-right onlyTabletMax">
            <div
              class="ninth-animation"
              :class="{
                'ninth-animation--active': showAnim,
              }"
            >
              <img
                v-if="flatsOneData[currentSlide].images.length > 0"
                :src="flatsOneData[currentSlide].images[0].file"
                class="ninth-right__img"
                :alt="`план ${flatsOneData[currentSlide].name}`"
              />
              <RFIcon
                v-else
                class="ninth-left-bottom-img__img--gray"
                icon-name="title"
              />
            </div>
          </div>
          <RFIcon
            v-if="currentSlide > 1"
            icon-name="arrow-left"
            class="ninth__arrow ninth__arrow-left"
            :class="{ 'ninth__arrow--disabled': showAnim }"
            @click.native="swipe('left')"
          />
          <RFIcon
            v-if="flatsOneMeta.total > 1"
            icon-name="arrow-right"
            class="ninth__arrow ninth__arrow-right"
            :class="{ 'ninth__arrow--disabled': showAnim }"
            @click.native="swipe('right')"
          />
        </div>
      </div>
    </div>
    <div
      class="ninth-flats-block"
      id="suggestions"
      v-if="isLook && flatsData"
      :class="{ 'ninth-flats-block--active': flatsActive }"
    >
      <section
        class="ninth-flats-block-top"
        :class="{ 'ninth-flats-block-top--active': flatsActive }"
      >
        <h3 class="ninth-flats-block__title">
          {{ textFormat(flatsOneMeta.total) }}
        </h3>
        <RFButton
          class="ninth-flats-block__btn--secondary"
          secondary
          @click="closeBlock"
        >
          <RFIcon icon-name="green-arrow" /> Свернуть
        </RFButton>
      </section>
      <div
        class="ninth-flats-block-bottom"
        :class="{ 'ninth-flats-block-bottom--active': flatsActive }"
      >
        <FlatsBlock :flatsData="flatsData" @select="selectFlat" />

        <Pagination
          :totalPages="flatsMeta.last_page"
          @select="changePage"
          class="ninth__pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Tabs from '@/components/molecules/Tabs/index.vue'
import RFIcon from '@/components/atoms/Icons/index.vue'
import RFButton from '@/components/atoms/Buttons/index.vue'
import FlatModal from '@/components/atoms/Modals/FlatModal.vue'
import FlatCard from '@/components/molecules/Cards/FlatModalCard/index.vue'
import FlatsBlock from '@/components/molecules/FlatsBlock/index.vue'
import Pagination from '@/components/atoms/Pagination/index.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { WOW } from 'wowjs'

Vue.use(VueAxios, axios)

export default {
  name: 'NinthSection',
  components: {
    Tabs,
    RFIcon,
    RFButton,
    FlatModal,
    FlatCard,
    FlatsBlock,
    Pagination,
  },
  data() {
    return {
      // для анимации закрывания модалки (на 1 сек)
      isHideModal: false,
      // для анимации закрывания нижнего блока (на 1 сек)
      flatsActive: false,
      // открыт ли нижний список
      isLook: false,
      // выбранный таб (из tabs)
      currentTab: 0,
      // выбранный слайд из верхнего списка
      currentSlide: 0,
      // для открытия выбранной квартиры из нижнего списка
      currentFlat: {},
      // определяет открываем из верхнего или нижнего списка
      isTop: true,
      // определяет класс для анимации при переключении (watch возвращает фолсе через 1с после изменения)
      showAnim: false,
      // определяет открытие боковой модалки с квартирой
      isShowFlatModal: false,
      tabs: [
        {
          label: '1-комнатная',
        },
        {
          label: '2-комнатная',
        },
        {
          label: '3-комнатная',
        },
        {
          label: '4-комнатная',
        },
      ],
      flatsOneData: [],
      flatsOneMeta: [],
      flatsData: [],
      flatsMeta: [],
    }
  },
  async created() {
    await this.changeValue
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  computed: {
    // Чекаем урл прод/дев
    checkUrl() {
      return window.location.href.includes('capital.orda-invest.kz')
    },
    changeValue() {
      switch (this.currentTab) {
        case 1:
          return this.fetchFlats(2)
        case 2:
          return this.fetchFlats(3)
        case 3:
          return this.fetchFlats(4)
        default: {
          return this.fetchFlats(1)
        }
      }
    },
    // Разделяем сумму на тысячи/млн и т.д.
    priceFormat() {
      return price => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
    // Склонения для предложений
    textFormat() {
      return count => {
        const declination = (number, txt, cases = [2, 0, 1, 1, 1, 2]) =>
          txt[
            number % 100 > 4 && number % 100 < 20
              ? 2
              : cases[number % 10 < 5 ? number % 10 : 5]
          ]

        return (
          count +
          ' ' +
          declination(count, ['предложение', 'предложения', 'предложений'])
        )
      }
    },
  },
  watch: {
    changeValue: {
      handler() {
        this.currentSlide = 0
        this.fetchFlats(this.currentTab + 1, 6)
      },
    },
    isShowFlatModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
    currentTab: {
      handler() {
        this.addAnimation()
      },
    },
  },
  methods: {
    closeBlock() {
      this.flatsActive = true
      setTimeout(() => {
        this.isLook = false
        this.flatsActive = false
      }, 1000)
    },
    addAnimation() {
      this.showAnim = true
      setTimeout(() => {
        this.showAnim = false
      }, 1500)
    },
    changePage(val) {
      this.fetchFlats(this.currentTab + 1, 6, val)
    },
    openModal() {
      this.fetchFlats(this.currentTab + 1, 6)
      this.isLook = true
    },
    selectFlat(flat) {
      this.isTop = false
      this.currentFlat = flat
      this.isShowFlatModal = true
    },
    changeCurrentTab(val) {
      this.currentTab = val
    },
    formatQuarter(val) {
      switch (val) {
        case 2:
          return 'II'
        case 3:
          return 'III'
        case 4:
          return 'IV'
        default:
          return 'I'
      }
    },
    swipe(val) {
      const arrayLength = this.flatsOneMeta.total
      this.addAnimation()
      setTimeout(() => {
        if (val === 'left' && this.currentSlide !== 0) {
          return this.currentSlide--
        } else if (val === 'right' && this.currentSlide !== arrayLength) {
          return this.currentSlide++
        }
        return val === 'left'
          ? (this.currentSlide = arrayLength)
          : (this.currentSlide = 0)
      }, 500)
    },
    closeModal() {
      this.isHideModal = true
      setTimeout(() => {
        this.isShowFlatModal = false
        this.isTop = true
        this.isHideModal = false
      }, 500)
    },
    async fetchFlats(room, count, page) {
      const roomCount = room || 1
      const perPage = count || 1000
      const currentPage = page || 1
      await axios
        .get(
          `${
            this.checkUrl
              ? 'https://new.orda-invest.kz/api/v1/'
              : 'https://orda-invest-api.rocketfirm.tech/api/v1/'
          }placements?page=${currentPage}&per_page=${perPage}&sort_order=desc&rooms_count=${roomCount}`,
        )
        .then(res => {
          setTimeout(() => {
            if (perPage === 1000) {
              this.flatsOneData = res.data.data.data
              this.flatsOneMeta = res.data.data.meta
            } else {
              this.flatsData = res.data.data.data
              this.flatsMeta = res.data.data.meta
            }
          }, 100)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ninth {
  width: 100%;
  height: fit-content;

  &-animation {
    width: 340px;
    height: 450px;
    transition: all 1.5s;

    @include mobile {
      height: 300px;
      width: 200px;
    }

    &--active {
      animation: marginAnim 1.5s;
      @keyframes marginAnim {
        0% {
          margin-right: 0;
          transform: rotate(0);
        }
        50% {
          margin-right: -2200px;
          transform: rotate(120deg);
        }
        100% {
          margin-right: 0;
          transform: rotate(0);
        }
      }
    }
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &-inner {
    animation: manifestation1 1s ease-in;
    gap: 30px;
    padding-top: 40px;
    justify-content: space-between;
    @include flex-row();

    @include tablet-portrait {
      padding-top: 0;
      flex-direction: column;
    }
    @include mobile {
      gap: 16px;
    }
  }

  &__arrow {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &--disabled {
      pointer-events: none;
    }
    &-left {
      position: absolute;
      top: 50%;
      left: 18px;

      @include mobile {
        top: 40%;
      }
    }
    &-right {
      position: absolute;
      top: 50%;
      right: 18px;

      @include mobile {
        top: 40%;
      }
    }
  }

  &__title {
    padding-bottom: 20px;
    animation: manifestation1 0.8s ease-in;
    @include font-styles(48px, 56px, 700, $c-dark, 0, $font-third);

    @include mobile {
      padding-bottom: 14px;
      @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
    }
  }

  &-left {
    align-items: flex-start;
    max-width: 600px;
    width: 100%;
    @include flex-column();

    @include tablet-portrait {
      max-width: 80%;
    }
    @include mobile-landscape {
      max-width: none;
      width: 100%;
      order: 2;
    }

    &-bottom {
      margin-top: 20px;
      height: 100%;
      width: 100%;
      gap: 20px;
      justify-content: center;
      @include flex-column();

      @include mobile-landscape {
        gap: 30px;
      }
      &-img {
        justify-content: center;
        @include flex-row();

        &__img {
          width: 345px;
          height: 450px;

          &--gray {
            padding: 0 86px;
            height: 450px;
            background-color: $c-bg-gray;

            @include tablet-portrait {
              padding: 0 40px;
            }
            @media (max-width: 560px) {
              height: 300px;
              width: 200px;
              padding: 0 15px;
            }
          }

          @include mobile-landscape {
            height: 400px;
          }

          @include mobile {
            width: 200px;
            height: 300px;
          }
        }
      }

      &__title {
        @include font-styles(38px, 46px, 700, $c-dark);

        @include mobile {
          @include font-styles(22px, 24px, 700, $c-dark);
        }
      }

      &__text {
        @include font-styles(15px, 21px, 400, $c-gray);

        @include mobile {
          @include font-styles(12px, 16px, 400, $c-gray);
        }
      }

      &__text--strong {
        @include font-styles(22px, 30px, 600, $c-dark);

        sup {
          @include font-styles(12px, 16px, 600, $c-dark);
        }

        @include mobile {
          @include font-styles(16px, 20px, 700, $c-dark);
        }
      }

      &__flex {
        align-items: flex-end;
        gap: 30px;
        @include flex-row();
      }

      &__area,
      &__date {
        @include flex-column();
      }

      &__tabs {
        padding-left: 35px;

        &--disabled {
          pointer-events: none;
        }

        @include tablet-portrait {
          padding: 0;
        }

        .tab {
          gap: 10px;
        }
      }

      &__card {
        background: $c-bg-gray;
        border-radius: 40px;
        gap: 20px;
        padding: 35px;
        height: fit-content;
        @include flex-column();

        @include mobile {
          border-radius: 20px;
          padding: 20px 15px;
          gap: 10px;
        }

        &--active {
          animation: opacityAnim 1.5s;
          @keyframes opacityAnim {
            0% {
              opacity: 1;
            }
            30% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }

      &__btns {
        gap: 20px;
        @include flex-row();

        @include mobile {
          gap: 10px;
          flex-direction: column;
        }

        &--primary.primary {
          padding: 10px 16px;
          @include font-styles(16px, 22px);
        }
        &--secondary.primary {
          padding: 10px 16px;
          border: 1px solid $c-main-green;
          background: inherit;
          @include font-styles(16px, 22px, 400, $c-main-green);

          &:hover {
            color: $white;
            background: $c-main-green;
          }
        }
      }
    }
  }
  &-right {
    max-width: 450px;
    width: 100%;
    justify-content: center;
    @include flex-row();
    &__img {
      width: 345px;
      height: 450px;
      image-rendering: optimizeQuality;
    }
  }
  &-flats-block {
    background: #f4f4f4;
    border-radius: 80px;
    padding: 40px 86px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    height: fit-content;
    gap: 30px;
    @include flex-column();

    &--active {
      animation: hideFlatBlock 1.5s;
      padding-top: 0;
      padding-bottom: 0;

      @keyframes hideFlatBlock {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
          margin-top: 0;
        }
      }
    }

    @include mobile {
      border-radius: 20px;
      padding: 20px 10px;
      gap: 15px;
    }

    &-top {
      justify-content: space-between;
      align-items: center;
      @include flex-row();

      overflow: hidden;
      &--active {
        animation: hideBlock 1.5s;

        @keyframes hideBlock {
          from {
            margin-top: 0;
          }
          to {
            margin-top: -100%;
          }
        }
      }
    }

    &__title {
      @include font-styles(38px, 46px, 700, $c-dark);

      @include mobile {
        @include font-styles(22px, 26px, 700, $c-dark);
      }
    }

    &__btn--secondary.primary {
      @include flex-row();
      gap: 5px;
      padding: 10px 16px;
      border: 1px solid $c-main-green;
      background: inherit;
      @include font-styles(16px, 22px, 400, $c-main-green);

      &:hover {
        background: $white;
      }
    }

    &-bottom {
      overflow: hidden;
      &--active {
        animation: hideBlock 1.5s;

        @keyframes hideBlock {
          from {
            margin-top: 0;
          }
          to {
            margin-top: -100%;
          }
        }
      }
    }
  }

  &__pagination {
    margin: 30px auto 0;

    @include mobile {
      margin-top: 20px;
    }
  }
}

::v-deep .tab {
  justify-content: flex-start;
  @include tablet-portrait {
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      min-width: fit-content;
    }
  }
}

.close-animation {
  ::v-deep .modal__body {
    animation: appearance 1s;

    @keyframes appearance {
      from {
        right: 0;
      }
      to {
        right: -100%;
      }
    }
  }
}
</style>
