<template>
  <div class="flat-card">
    <img
      v-if="flatData.images.length > 0"
      :src="flatData.images[0].file"
      class="flat-card__img"
      :alt="`план ${flatData.name}`"
    />
    <RFIcon
      v-else
      class="flat-card__img flat-card__img--gray"
      icon-name="title"
    />
    <section class="flat-card-bottom">
      <h4 class="flat-card-bottom__title">{{ flatData.name }}</h4>
      <div>
        <div class="flat-card-bottom-item">
          <span class="flat-card-bottom-item__text">Площадь</span>
          <span class="flat-card-bottom-item__text--dark"
            >{{ flatData.full_area }} м<sup>2</sup>
          </span>
        </div>
        <div class="flat-card-bottom-item">
          <span class="flat-card-bottom-item__text">Этаж</span>
          <span class="flat-card-bottom-item__text--dark"
            >{{ flatData.floor }}
          </span>
        </div>
        <div class="flat-card-bottom-item">
          <span class="flat-card-bottom-item__text">Блок</span>
          <span class="flat-card-bottom-item__text--dark"
            >{{ flatData.blockName }}
          </span>
        </div>
      </div>
      <span class="flat-card-bottom-item__text--green">
        {{ priceFormat(flatData.cost) }} ₸
      </span>
    </section>
  </div>
</template>

<script>
import RFIcon from '@/components/atoms/Icons/index.vue'

export default {
  name: 'FlatCard',
  components: { RFIcon },
  props: {
    flatData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDesktop: true,
      active: false,
    }
  },
  computed: {
    priceFormat() {
      return price => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flat-card {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  background: $white;
  padding: 30px 20px 20px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;

  @include mobile {
    width: 100%;
  }

  &__img {
    width: 200px;
    height: 200px;
    margin: 0 auto 30px;

    @include mobile {
      margin-bottom: 40px;
    }

    &--gray {
      background-color: $c-bg-gray;
    }
  }

  &-bottom {
    gap: 20px;
    @include flex-column();

    @include mobile {
      gap: 14px;
    }

    &__title {
      padding-bottom: 30px;
      @include font-styles(22px, 24px, 700, $c-dark, 0, $font-third);

      @include mobile {
        padding-bottom: 0;
      }
    }
    &-item {
      justify-content: space-between;
      @include flex-row();
      &:not(:first-of-type) {
        padding-top: 5px;
      }
      &__text {
        @include font-styles(15px, 22px, 400, $c-gray);
      }

      &__text--dark {
        @include font-styles(15px, 22px, 400, $c-dark);

        sup {
          @include font-styles(12px, 16px, 400, $c-dark);
        }
      }
      &__text--green {
        @include font-styles(22px, 30px, 600, $c-main-green);
      }
    }
  }
}
</style>
